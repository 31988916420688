/** @format */

import aio_5_img from "../assets/AIO-5/aio.webp";
import TR100 from "../assets/otherImages/TR100.webp";
import CG2 from "../assets/otherImages/cg2.webp";
import G3 from "../assets/otherImages/G3.webp";
export const products = [
	{
		name: "AIO-5",
		description: "Smart Riding System",
		src: aio_5_img,
		alt: "AIO_5_img",
	},
	{
		name: "TR100",
		description: "Two-Way Charging Hub",
		src: TR100,
		alt: "TR100_img",
	},
	{
		name: "CG2",
		description: "Wireless Charger Phone Holder",
		src: TR100,
		alt: "CG2_img",
	},
	{
		name: "G3",
		description: "The Pressure Monitor",
		src: TR100,
		alt: "G3_img",
	},
];
