/** @format */

import React from "react";
import AppHeader from "../components/AppHeader";
import ProductsWrapper from "../components/ProductsWrapper";
import Footer from "../components/Footer";
import HeaderFooterRenderer from "./headerFooterRenderer";

const ProductsHome = () => {
	return (
		<>
			<HeaderFooterRenderer>
				<ProductsWrapper />
			</HeaderFooterRenderer>
		</>
	);
};

export default ProductsHome;
