/** @format */

import React from "react";
import AppHeader from "../components/AppHeader";
import Footer from "../components/Footer";
import HeaderFooterRenderer from "./headerFooterRenderer";

const AccessoriesHome = () => {
	return (
		<>
			<HeaderFooterRenderer>Work in progress</HeaderFooterRenderer>
		</>
	);
};

export default AccessoriesHome;
