/** @format */

import { Card, CardContent, CardMedia, Grid } from "@mui/material";
import React from "react";
import G3DetailsImage from "../../assets/otherImages/G3Details.png";

const G3details = () => {
	return (
		<>
			<Grid
				container
				spacing={3}
				margin={3}
				xs={12}>
				<Grid
					item
					xs={5}>
					<Card>
						<CardMedia
							component='img'
							height='140'
							src={G3DetailsImage}
							alt={"g3image"}
						/>
					</Card>
				</Grid>
				<Grid
					item
					xs={6}>
					<h2>specification here</h2>
				</Grid>
			</Grid>
		</>
	);
};

export default G3details;
