/** @format */

import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Cards = (props) => {
	const navigate = useNavigate();
	const handleClick = () => {
		if (props.name === "G3") {
			navigate("/products/g3Details");
		}
	};
	return (
		<Card sx={{ margin: "1rem", width: "33%" }}>
			<CardMedia
				component='img'
				height='140'
				src={props.src}
				alt={props.alt}
			/>
			<CardContent>
				<Typography
					gutterBottom
					variant='h5'
					component='div'>
					{props.name}
				</Typography>
				<Typography
					variant='body2'
					color='text.secondary'>
					{props.description}
				</Typography>
			</CardContent>
			<CardActions>
				<Button
					size='small'
					onClick={handleClick}>
					More Details
				</Button>
			</CardActions>
		</Card>
	);
};

export default Cards;
