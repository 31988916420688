/** @format */

import React from "react";
import AppHeader from "../components/AppHeader";
import Footer from "../components/Footer";

const HeaderFooterRenderer = (props) => {
	return (
		<>
			<AppHeader />
			{props.children}
			<Footer />
		</>
	);
};

export default HeaderFooterRenderer;
