/** @format */

import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
	Avatar,
	Box,
	Button,
	CardActionArea,
	CardActions,
	Checkbox,
	Container,
	FormControlLabel,
	Grid,
	Link,
	Paper,
	TextField,
} from "@mui/material";
import { Padding } from "@mui/icons-material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const Login = () => {
	return (
		<Container
			component='main'
			maxWidth='xs'>
			<Paper
				elevation={3}
				square={false}>
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						padding: "3rem",
					}}>
					<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography
						component='h1'
						variant='h5'>
						Sign in
					</Typography>
					<Box
						component='form'
						noValidate
						sx={{ mt: 1 }}>
						<TextField
							margin='normal'
							required
							fullWidth
							id='email'
							label='Email Address'
							name='email'
							autoComplete='email'
							autoFocus
						/>
						<TextField
							margin='normal'
							required
							fullWidth
							name='password'
							label='Password'
							type='password'
							id='password'
							autoComplete='current-password'
						/>
						<FormControlLabel
							control={
								<Checkbox
									value='remember'
									color='primary'
								/>
							}
							label='Remember me'
						/>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2 }}>
							Sign In
						</Button>
						<Grid container>
							<Grid
								item
								xs>
								<Link
									href='#'
									variant='body2'>
									Forgot password?
								</Link>
							</Grid>
							<Grid item>
								<Link
									href='#'
									variant='body2'>
									{"Don't have an account? Sign Up"}
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Paper>
		</Container>
	);
};

export default Login;
