/** @format */

import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import Welcome from "../pages/Welcome";
import Products from "../pages/Products";
import ProductsHome from "../pages/ProductsHome";
import AccessoriesHome from "../pages/AccessoriesHome";
import G3DetailPage from "../pages/G3DetailPage";

const MainRoutes = createBrowserRouter([
	{
		path: "/",
		element: <Welcome />,
	},
	{
		path: "/signIn",
		element: <Login />,
	},
	{
		path: "/signUp",
		element: <SignUp />,
	},
	{ path: "/products", element: <ProductsHome /> },
	{ path: "/accessories", element: <AccessoriesHome /> },
	{ path: "/products/g3Details", element: <G3DetailPage /> },
]);

export default MainRoutes;
