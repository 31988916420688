/** @format */

import React from "react";
import Error from "./Error";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Video from "../components/Video";
import AppHeader from "../components/AppHeader";
import ProductsWrapper from "../components/ProductsWrapper";

const Welcome = () => {
	return (
		<div>
			<AppHeader />
			<Video />
			<ProductsWrapper />
			<Footer />
		</div>
	);
};

export default Welcome;
