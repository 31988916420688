/** @format */

import React from "react";
import Cards from "../components/Card";
import { Box } from "@mui/material";
import { products } from "../utility/constants";
//let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const Products = () => {
	return (
		<>
			<Box
				display={"flex"}
				flexWrap={"wrap"}
				justifyContent={"space-around"}
				marginTop={"2rem"}>
				{products.map((t, i) => (
					<Cards
						key={i}
						name={t.name}
						description={t.description}
						src={t.src}
						alt={t.alt}></Cards>
				))}
			</Box>
		</>
	);
};

export default Products;
