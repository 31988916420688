/** @format */

import "./output.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Welcome from "./pages/Welcome";
import { RouterProvider } from "react-router-dom";
import MainRoutes from "./routes/MainRoutes";
import AppHeader from "./components/AppHeader";

function App() {
	return (
		<>
			<RouterProvider router={MainRoutes}>
				<Welcome />
			</RouterProvider>
		</>
	);
}

export default App;
