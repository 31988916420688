/** @format */

import React from "react";
import HeaderFooterRenderer from "./headerFooterRenderer";
import G3details from "./deatilPages/G3details";

const G3DetailPage = () => {
	return (
		<HeaderFooterRenderer>
			<G3details />
		</HeaderFooterRenderer>
	);
};

export default G3DetailPage;
