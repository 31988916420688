/** @format */

import { Paper, Typography } from "@mui/material";
import React from "react";
import Products from "../pages/Products";

const ProductsWrapper = () => {
	return (
		<>
			<Products />
		</>
	);
};

export default ProductsWrapper;
