/** @format */

import React from "react";
import ReactPlayer from "react-player/lazy";
import video from "../assets/video/myvideo.mp4";
const Video = () => {
	return (
		<ReactPlayer
			url={video}
			playing={true}
			loop={true}
			volume={0}
			muted={true}
			width={"100%"}
			height={"100%"}
			playsinline={true}
		/>
	);
};

export default Video;
